import React, { useState } from "react"
import { VENDOR_DUMPSTER_RENTAL, VENDOR_JUNK_REMOVAL } from "../../data/vendors"
import {
  TYPE_DUMPSTER_RENTAL,
  TYPE_JUNK_REMOVAL,
  TYPE_TO_DISPLAY_NAME,
} from "./constants"
import FilterPanel from "./FilterPanel"
import ListingsBlade from "./ListingsBlade"
import styles from "./ListingsShell.module.css"
import { navigate } from "@reach/router"
import SortBar from "./SortBar"
import { calculateDumpsterRemovalPrice, calculateJunkRemovalPrice } from "../../data/pricing"
import { junkDescriptions } from "../../data/productData"



export default function ListingsShell({ type, searchParams, location }) {
  const [touched, setTouched] = useState({})
  let loadSize = searchParams.loadSize
  if (!loadSize && !touched["loadSize"]) {
    loadSize = "25"
  }
  let description = searchParams.description;
  if (!description && !touched["description"]) {
    description = junkDescriptions[0].value;
  }
  let cubicYardSize = searchParams.cubicYardSize
  if (!cubicYardSize && !touched["cubicYardSize"]) {
    cubicYardSize = "10"
  }
  let rentalDays = (searchParams.rentalDays && Number(searchParams.rentalDays))
  if (!rentalDays && !touched["rentalDays"]) {
    rentalDays = 5
  }
  let vendor
  let price
  let checkoutUrl
  if (type === TYPE_DUMPSTER_RENTAL) {
    vendor = VENDOR_DUMPSTER_RENTAL
    price = (vendorId) => calculateDumpsterRemovalPrice(cubicYardSize, rentalDays, vendorId)
    checkoutUrl = `/checkout/?type=${type}&cubicYardSize=${cubicYardSize}&rentalDays=${rentalDays}&previousUrl=${encodeURIComponent(location)}`
  }
  if (type === TYPE_JUNK_REMOVAL) {
    vendor = VENDOR_JUNK_REMOVAL
    price = (vendorId) => calculateJunkRemovalPrice(loadSize, vendorId)
    checkoutUrl = `/checkout/?type=${type}&loadSize=${loadSize}&description=${description}&previousUrl=${encodeURIComponent(location)}`
  }
  const blades = vendor.map(a => (
    <ListingsBlade
      key={a.name}
      price={price(a.id)}
      title={a.name}
      rating={a.rating}
      workHours={a.workHours}
      summary={a.summary || ""}
      checkoutUrl={`${checkoutUrl}&vendor=${a.id}`}
    />
  ))
  return (
    <div className={styles.grid}>
      <div className={styles.bladeBox}>
        <h2>Filters</h2>
        <FilterPanel
          type={type}
          loadSize={loadSize}
          description={description}
          cubicYardSize={cubicYardSize}
          rentalDays={rentalDays}
          onChange={changes => {
            setTouched({
              ...touched,
              ...Object.keys(changes).reduce((accum, current) => {
                  return ({...accum, [current]: true });
              }, {}),
            })
            const params = new URLSearchParams({ ...searchParams, ...changes })
            navigate(`/search/?${params.toString()}`)
          }}
        />
      </div>
      <div className={styles.bladeBox}>
        <h2>Select Your Provider For {TYPE_TO_DISPLAY_NAME[type]}</h2>
        <SortBar min={blades.length} max={vendor.length} />
        {blades}
      </div>
    </div>
  )
}
