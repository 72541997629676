import React from 'react';
import { TYPE_DUMPSTER_RENTAL, TYPE_JUNK_REMOVAL } from './constants';
import ListingsShell from './ListingsShell';

/**
 *
 *
 * @export
 * @param {{ zip: string}} { zip }
 */
export default function Listings({ zip, type, searchParams, location }) {
    if(!zip || !zip.startsWith("30")) {
        return <h2>Thank you for your interest. Unfortunately we do not serve your area at this time</h2>
    }
    if(type !== TYPE_DUMPSTER_RENTAL && type !== TYPE_JUNK_REMOVAL) {
        return <h2>An internal error occured</h2>
    }
    return <ListingsShell type={type} searchParams={searchParams} location={location} />

    
}