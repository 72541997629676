import React, { useEffect, useState, Fragment } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Listings from "../components/listings/index.js"
import { Location } from "@reach/router";

const SecondPage = ({ location }) => {
  const search = location?.search
  const relativeUrl = `${location.pathname}${search}`;
  const searchParams = Object.fromEntries(new URLSearchParams(search))
  const [loading, setLoading] = useState(true)
  const breadcrumb = searchParams?.zip && `Your search: ${searchParams.zip}`;
  useEffect(() => {
    setLoading(false)
  }, [])
  return (
    <Fragment>
      <SEO title="Search" />
      <Layout fluid={false} breadcrumbText={breadcrumb}>
        {loading && <h2>Loading...</h2>}
        {!loading && (
          <Listings zip={searchParams.zip} type={searchParams.type} searchParams={searchParams} location={relativeUrl}/>
        )}
      </Layout>
    </Fragment>
  )
}

export default function WrappedSecondPage() {
  return <Location>{({location}) => { 
    return <SecondPage location={location} />
  }}</Location>
}
