import React, { Fragment } from "react"
import { junkDescriptions } from "../../../data/productData"
import { TYPE_DUMPSTER_RENTAL, TYPE_JUNK_REMOVAL } from "../constants"
import styles from "./index.module.css"

function JunkPanel({ loadSize, description, onChange }) {
  return (
    <Fragment>
      <h4 className={styles.line}>Load Size: </h4>
      <div className={styles.line}>
        <select
          value={loadSize}
          name="size"
          onChange={e => {
            e.preventDefault()
            onChange({ loadSize: e.currentTarget.value })
          }}
        >
          <option value="25">25% of truck</option>
          <option value="50">50% of truck</option>
          <option value="75">75% of truck</option>
          <option value="100">100% of truck</option>
        </select>
      </div>
      <h4 className={styles.line}>Description:</h4>
      <div className={styles.line}>
      <select
          value={description}
          name="description"
          onChange={e => {
            e.preventDefault()
            onChange({ description: e.currentTarget.value })
          }}
        >
          {junkDescriptions.map(i => <option key={i.name} value={i.value}>{i.name}</option>)}
        </select>
      </div>
    </Fragment>
  )
}

function DumpsterRemovalPanel({ cubicYardSize, rentalDays, onChange }) {
  return (
    <Fragment>
      <h4 className={styles.line}>Cubic Yards: </h4>
      <div className={styles.line}>
        <select
          value={cubicYardSize}
          name="size"
          onChange={e => {
            e.preventDefault()
            onChange({ cubicYardSize: e.currentTarget.value })
          }}
        >
          <option value="10">10 Cubic Yards</option>
          <option value="15">15 Cubic Yards</option>
          <option value="20">20 Cubic Yards</option>
        </select>
      </div>
      <h4 className={styles.line}>Number of rental days:</h4>
      <div className={styles.line}>
        <input
          type="number"
          min="1"
          value={rentalDays}
          className={styles.smBox}
          onChange={e => {
            e.preventDefault()
            onChange({ rentalDays: e.currentTarget.value })
          }}
        />{" "}
        days
      </div>
    </Fragment>
  )
}

export default function FilterPanel({
  type,
  onChange,
  loadSize,
  description,
  cubicYardSize,
  rentalDays,
}) {
  return (
      <div className={styles.filterPanel}>
        <div>Tell us more to help us find a vendor</div> 
        <hr />
        {type === TYPE_JUNK_REMOVAL && (
          <JunkPanel
            type={type}
            loadSize={loadSize}
            description={description}
            onChange={onChange}
          />
        )}
        {type === TYPE_DUMPSTER_RENTAL && (
          <DumpsterRemovalPanel
            type={type}
            onChange={onChange}
            cubicYardSize={cubicYardSize}
            rentalDays={rentalDays}
          />
        )}
      </div>
  )
}
