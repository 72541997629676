import React from "react"
import styles from "./ListingsBlade.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faStar } from '@fortawesome/free-regular-svg-icons'
import SubmitButton from "./SubmitButton"
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons"
import Star from "../common/Star"
export default function ListingsBlade({
  title,
  price,
  submit,
  workHours,
  summary,
  img,
  rating,
  checkoutUrl
}) {
    let stars = [];
    if (rating) {
        for(let i = 1; i <= rating; i++) {
            stars.push(<Star key={`${i}-filled`} filled={true} />)
        }
        for(let n = stars.length; n < 5; n++ ) {
            stars.push(<Star key={`${n}-hollow`} filled={false} />)
        }
    }
  return (
    <div className={styles.blade}>
      <div className={styles.line}>
        <h3 className={styles.bladeTitle}>{title}</h3>
        <div className={styles.spacer}></div>
        <b>${price}</b>
      </div>
      <div className={styles.line}>
        <span className={styles.small}><span className={styles.icon}><FontAwesomeIcon icon={faClock} /></span>Available to work: {workHours}</span>
      </div>
      <div className={styles.line}>
          {stars}
      </div>
      <div className={styles.line}>
        <span className={styles.small}>{summary}</span>
      </div>
      <div className={styles.line}>
        <div className={styles.spacer}></div>
        <SubmitButton url={checkoutUrl}/>
      </div>
    </div>
  )
}
