import React from 'react';
import styles from "./Button.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { navigate } from 'gatsby';

export default function Button({ onClick, children, type="submit" }) {
    // const [loading, setLoading] = useState(false);
    // const [submitted, setSubmitted] = useState(false);
    return <button className={styles.submit} type={type} onClick={onClick}>{children}</button>
    // let text;
    // if(loading){
    //     text = <FontAwesomeIcon icon={faSpinner} spin={true} />
    // } else {
    //     text = submitted ? "Submitted" : "Book Now";
    // }
    // return <button onClick={() => {
    //     setLoading(true);
    //     setTimeout(() => {
    //         setLoading(false);
    //         setSubmitted(true);
    //     }, 300)
    // }} disabled={submitted} className={styles.submit}>{text}</button>
}